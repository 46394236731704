#portfolio {
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem 0 2rem 0;
   
    overflow: hidden;
}

.portfolioTitle {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: 600;
    margin-bottom: 4rem;
    text-decoration: underline yellow;
    text-underline-offset: 1rem
}

.portfolioDescription  {
    letter-spacing: 1px;
    text-align: center;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 4rem;
   padding: 0 8rem;
}

#portfolio img {
    object-fit: cover;
    width: 160px;
    margin: 0 2rem;
    border-radius: 50%;
    margin-bottom: 20px;
}

#portfolio img:hover {
    filter: blur(1px);
}

@media only screen and (max-width:767px) {
    #portfolio {
        display: none;
    }
}