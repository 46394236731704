.navbar {
    height: 10vh;
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: rgb(30,30,30);
    overflow: hidden;
}

.logo {
    object-fit: cover;
    width: 5rem;
    height: 4rem;
}

.desktopMenuListItem {
    margin: 0 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    
    padding-bottom: 0.5rem;
    border-bottom: 3px solid yellow;
}

@media only screen and (max-width:767px) {
    .navbar button {
        display: none;
    }
}
