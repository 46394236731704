#skills {
    max-width: 75rem;
    margin: 0 auto;
    padding: 2rem 0 0;
}

.whatIDo {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: 600;
    margin-bottom: 4rem;
    
    text-decoration: underline yellow;
    text-underline-offset: 1rem
}

.skillsDescription  {
    letter-spacing: 1px;
    text-align: center;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 4rem;
   padding: 0 8rem;
}

.skillBars {
    display: flex;
    background: #505050;
    margin: 0 8rem 3rem;
    padding: 1rem;
    border-radius: 0.5rem;
}

.skillBarText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
}

.skillBarImg {
    width: 7rem;
    object-fit: cover;
    padding: 1rem;
   
}

.skillBarText p {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 300;
}


@media only screen and (max-width:767px) {
    .skillsDescription  {
        
       padding: 0 1rem;
    }

    #skills {
        display: none;
    }
}
