#client {
    max-width: 75rem;
    margin: 0 auto;
    padding: 3rem 0 0;
   
    overflow: hidden;
}

.clientTitle {
    text-align: center;
    font-size: 2.5rem;
    line-height: 1.6;
    font-weight: 600;
    margin-bottom: 4rem;
    text-decoration: underline yellow;
    text-underline-offset: 1rem
}

.clientDescription  {
    letter-spacing: 1px;
    text-align: center;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 4rem;
   padding: 0 8rem;
}

#client img {
    object-fit: cover;
    width: 240px;
    margin: 2rem 20px 2rem 20px;
}

#client img:hover {
    filter: blur(1px);
}

@media only screen and (max-width:767px) {
    #client {
        display: none;
    }
}