#intro {
    max-width: 75rem;
    height: 90vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    
}

.bg {
    position: absolute;
    z-index: -1;
    top: 2rem;
    right: 0;
    object-fit: cover;
    height: 90vh;
    width: auto;
   filter:brightness(80%)
}



.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    line-height: 1.4;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}

.introText {
    margin: 1rem 0;
}

.hello {
    font-size: 1.75rem;
    font-weight: 300;
}

.introName {
    color: yellow
}

.introPara {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
}

.btnImage {
    width: 1.4rem;
    object-fit: cover;
    margin: 0px 0.5rem;
}

.btn {  
    display: flex;   
    align-items: center;
    margin: 2rem 0;
}

@media only screen and (max-width:767px) {
    .bg {
        top:2rem;
        height: auto;
        width: 95vw;
        left: 10px;
    }

    .introContent {      
    font-size: 2.5rem;
        
    }

    .introPara {
        font-size: 1.3rem;
    }

   
}
